<template>
    <v-app>
        <div class="row">
            <div class="col-12">
                <div class="card">
                    <div class="card-header">
                        <div class="card-title pt-3 px-3 d-flex justify-content-between">
                            <div class="breadcrumb-left">
                                <h4>
                                Schedules
                                </h4>
                                <div class="breadcrumb-sub-header">
                                <router-link to="/dashboard">Dashboard </router-link>\ Calendar view
                                </div>
                            </div>
                            <div class="breadcrumb-right">
                                
                            </div>
                        </div>
                    </div>

                    <div class="card-body">
                        <div class="search-wrapper border-outline mb-5 px-3 py-5 d-sm-flex justify-content-start">
                            <div class="row">
                                <v-col class="d-flex" cols="12" md="3">
                                    <v-select 
                                        :items="scores" 
                                        v-model="search.score_id" 
                                        label="Score" 
                                        item-text="name"  
                                        clearable
                                        :disabled="currentUser.access_type == 'score'"
                                        item-value="id" 
                                        outlined 
                                        dense
                                        @change="handleScoreChange"
                                    ></v-select>
                                </v-col>

                                <v-col class="d-flex" cols="12" md="3">
                                    <v-select 
                                        :items="types" 
                                        v-model="search.type" 
                                        label="Search for" 
                                        item-text="name"  
                                        item-value="value" 
                                        clearable
                                        outlined 
                                        dense
                                    ></v-select>
                                </v-col>

                                <v-col class="d-flex" cols="12" md="3">
                                    <v-autocomplete
                                        label="Venue"
                                        outlined
                                        dense
                                        :items="venues"
                                        item-text="name"
                                        item-value="id"
                                        :loading="venueLoading"
                                    ></v-autocomplete>
                                </v-col>

                                <v-col class="d-flex" cols="12" sm="3">
                                    <v-select 
                                        :items="userTypes" 
                                        v-model="search.user_type" 
                                        label="User type" 
                                        item-text="name"  
                                        item-value="value" 
                                        clearable
                                        outlined 
                                        dense
                                    ></v-select>
                                </v-col>

                                <v-col cols="12" sm="6" md="3">
                                    <v-autocomplete
                                        outlined
                                        dense
                                        :items="users"
                                        item-text="display_text"
                                        item-value="id"
                                        v-model="search.user"
                                        label="User"
                                        :loading="userLoading"
                                        clearable
                                        :search-input.sync="userSearch"
                                        placeholder="Search examiner, supervisor, examiner"
                                    >
                                    <template v-slot:label>
                                        User (Type to search)
                                    </template>
                                    <template v-slot:no-data>
                                        <div class="no-data-auto-complete text-center">No data available </div>
                                    </template>
                                    </v-autocomplete>
                                </v-col>
                                
                                <v-col cols="12" md="2">
                                    <v-btn :loading="isSearching" @click="searchData" class="mt-1 btn btn-primary" style="color: #fff">
                                        <v-icon small elevation="2" outlined>fas fa-search</v-icon>&nbsp;
                                        Search
                                    </v-btn>
                                </v-col>
                            </div>
                        </div>

                        <div class="row">
                            <v-col cols="12">
                                <v-sheet
                                    tile
                                    height="54"
                                    class="d-flex"
                                >
                                    <v-btn
                                        icon
                                        class="ma-2"
                                        @click="prev()"
                                    >
                                        <v-icon>mdi-chevron-left</v-icon>
                                    </v-btn>
                                    <v-btn
                                        icon
                                        class="ma-2"
                                        @click="next()"
                                    >
                                        <v-icon>mdi-chevron-right</v-icon>
                                    </v-btn>
                                </v-sheet>

                                <v-sheet class="mt-3" height="700">
                                    <v-calendar
                                        ref="calendar"
                                        v-model="focus"
                                        :type="ctype"
                                        :events="resultLists"
                                        @click:event="showEvent"
                                        :event-overlap-threshold="30"
                                    ></v-calendar>
                                </v-sheet>
                            </v-col>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </v-app>
</template>

<script>
    import ExamSession from "@/services/practical-exam/exam-session/ExamSession";
    import ScoreService from "@/services/score/score/ScoreService";
    import ScheduleSearchService from "@/services/schedule-search/ScheduleSearchService";
    import VenueService from "@/services/practical-exam/venue/VenueService";

    const examSession = new ExamSession();
    const score = new ScoreService();
    const scheduleSearch = new ScheduleSearchService();
    const venue = new VenueService();


    export default{
        data(){
            return{
                ctype: "month",
                types: ["month", "week", "day", "4day"],
                type: "month",
                currentMonth: new Date().getMonth() + 1,
                currentYear: new Date().getFullYear(),
                focus: new Date(), // Initial focus date
                result_lists: [],
                scores: [],
                isSearching: false,
                types:[
                    {
                        name:'Sessions',
                        value: 'session'
                    },
                    {
                        name:'Schedules',
                        value: 'schedule'
                    },
                    {
                        name:'All',
                        value: 'all'
                    },
                ],
                userTypes:[
                    {
                        name:'Examiner',
                        value: 'examiner'
                    },
                    {
                        name:'Supervisor',
                        value: 'supervisor'
                    },
                ],
                search:{
                    score_id: null,
                    venue_id: null,
                    type: 'all',
                    user: null,
                    user_type:''
                },
                userLoading: false,
                users: [],
                userSearch: null,
                venueLoading: false,
                venues: []
            }
        },
        methods:{
            getScores(){
                score
                .all()
                .then((res) => {
                    this.scores = res.data.scores;
                    if(this.currentUser.access_type == 'score'){
                        this.search.score_id = this.currentUser.score_id;
                    }else{
                        this.search.score_id = null;
                    }
                })
            },

            showEvent({ nativeEvent, event }) {
            // Handle event click
            },
            
            prev() {
                const prevMonthDate = new Date(
                    this.focus.getFullYear(),
                    this.focus.getMonth() - 1,
                    1
                );
                this.focus = prevMonthDate;

                if (prevMonthDate.getMonth() === 0) {
                    this.focus = new Date(this.focus.getFullYear() - 1, 11, 1);
                }

                this.getExamSessionByTime(); // Fetch data for the new month
            },

            next() {
                const nextMonthDate = new Date(
                    this.focus.getFullYear(),
                    this.focus.getMonth() + 1,
                    1
                );
                this.focus = nextMonthDate;

                if (nextMonthDate.getMonth() === 11) {
                    this.focus = new Date(this.focus.getFullYear() + 1, 0, 1);
                }

                this.getExamSessionByTime(); // Fetch data for the new month
            },
            
            searchData() {
                this.isSearching =  true;
                this.result_lists = [];

                let data = {
                    date: this.focus.getFullYear()+'-'+parseInt(this.focus.getMonth()+1)+'-01', 
                    score_id: this.search.score_id,
                    type: this.search.type,
                    user: this.search.user,
                    user_type: this.search.user_type
                };

                scheduleSearch
                .filterByTime(data)
                .then((res) => {
                    this.result_lists = res.data;
                })
                .catch((err) => {
                })
                .finally(() => {
                    this.isSearching =  false;
                });
            },

            handleScoreChange(){
                if(this.search.score_id){
                    this.getPrivateVenuesByScore();
                }else{
                    this.search.venue_id = null;
                    this.venues = [];
                }
            },

            getPrivateVenuesByScore(){
                this.venueLoading = true;

                venue
                .getPrivateVenuesByScore(this.search.score_id)
                .then((res) => {
                    this.venues = res.data.venues;
                })
                .catch((err) => {

                })
                .finally(() => {
                    this.venueLoading = false;
                })
            }
        },
        computed: {
            currentUser() {
                return this.$store.getters.currentUser;
            },
            resultLists() {
                const results = [];
                this.result_lists.forEach((item) => {
                    results.push({
                        name: item.name,
                        start: item.exam_date,
                        end: item.exam_date,
                        // content: item.content,
                        color: item.is_confirmed ? 'green' : 'blue',
                        timed: false,
                    })
                })
                return results;
            },
        },
        watch:{
            userSearch(val) {
                this.users = [];
                let data = {
                    name:val,
                    score_id: this.search.score_id
                };

                scheduleSearch
                .searchForUser(data)
                .then((response) => {
                    response.data.users.map((user) => {
                        let data = user;
                        data.display_text = user.full_name+'('+user.email+')' ;
                        this.users.push(data);
                    });
                })
                .catch((err) => {

                })
                .finally(() => (this.userLoading = false));
            },
        },
        mounted(){
            this.getScores();
        }
    }
</script>