import Apiservice from '@/common/api.service';
import {API_URL} from '@/common/config.js'
import { url } from 'vuelidate/lib/validators';

const apiService = Apiservice;

export default class ScheduleSearchService
{
    #api = null;
    constructor() {
        this.#api = API_URL + 'admin/schedule-search';
    }

    searchForUser(data={}){
        let url = `${this.#api}/user-search`;
        let param = {params: data};
        return apiService.query(url, param);
    }

    filterByTime(data={}){
        let url = `${this.#api}/filter/by-time`;
        let param = {params: data};
        return apiService.query(url, param);
    }
}